<template>
  <div id="home" class="overflow-x-clip h-full absolute w-full">
    <header class="w-full sm:h-3/5 bg-[#17558580]">
      <section class="h-full topheader flex justify-center">
        <nav class="duration-300 text-white fixed w-full bg-[#175585]/80 z-50 backdrop-blur-md font-[roboto]">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-14">
              <!-- Logo -->
              <a href="" class="flex items-center gap-2 font-bold text-lg italic">
                <img loading="lazy" alt="Picture of a football" title="Picture of a football" class="w-5" src="../assets/football.png">
                <span>TAS</span>
              </a>

              <!-- Desktop Navigation -->
              <div class="hidden sm:flex items-center space-x-1">
                <template v-for="item in ['home', 'news', 'teams', 'committee', 'policies']">
                  <button @click="ScrollTo(item)" 
                    class="px-4 py-2 text-sm font-medium hover:bg-white/10 rounded-md duration-200">
                    {{ item.charAt(0).toUpperCase() + item.slice(1) }}
                  </button>
                </template>
                
                <button @click="ScrollTo('contact')" 
                  class="ml-4 px-6 py-2 text-sm font-medium bg-[#4B8BF4] hover:bg-[#4B8BF4]/80 rounded duration-200">
                  Contact
                </button>
              </div>

              <!-- Mobile menu button -->
              <button @click="expandnav" class="flex sm:hidden mt-3">
                <div class="inline-flex items-center justify-center p-2 rounded-md hover:bg-white/10 duration-200">
                  <div class="w-6 flex flex-col gap-1.5">
                    <div v-for="n in 3" class="w-full h-0.5 bg-white rounded-full duration-200"></div>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- Mobile Navigation -->
          <div class="sm:hidden hiddennav duration-300 -translate-y-10 hidden">
            <div class="px-2 pt-2 pb-3 space-y-1">
              <template v-for="item in ['home', 'news', 'teams', 'committee', 'policies', 'contact']">
                <button @click="ScrollTo(item)" 
                  class="block w-full px-3 py-2 text-base font-medium hover:bg-white/10 rounded-md duration-200">
                  {{ item.charAt(0).toUpperCase() + item.slice(1) }}
                </button>
              </template>
            </div>
          </div>
        </nav>

        <section class="inline-flex flex-col items-start sm:pl-20 pl-8 w-full py-20 sm:pt-24 backdrop-blur-sm">
          <span class="text-white/80 text-sm sm:text-base tracking-[0.3em]">WELCOME TO</span>
          
          <h1 class="text-white flex flex-col leading-[1.1] font-bold mt-2">
            <span class="md:text-6xl text-5xl tracking-wide hover:translate-y-1 transition-transform duration-300">TORQUAY</span>
            <span class="md:text-6xl text-5xl tracking-wide hover:translate-y-1 transition-transform duration-300">ACADEMY</span>
            <span class="text-[#4B8BF4] md:text-6xl text-5xl tracking-wide hover:translate-y-1 transition-transform duration-300">SPORT F <router-link to="/service" class="cursor-text -ml-3">C</router-link></span>
          </h1>

          <p class="opacity-90 font-light w-full sm:w-[460px] mt-6 text-sm sm:text-lg text-white">
            Welcome to the official Torquay Academy Football site. Check out our club!
          </p>
          
          <!-- <button @click="ScrollTo('teams')" 
            class="bg-[#4B8BF4] hover:bg-[#4B8BF4]/80 text-white py-3 px-8 rounded mt-8 mb-10 text-sm sm:text-base font-medium tracking-wide duration-300">
            VIEW OUR TEAMS
          </button> -->
        </section>
      </section>
    </header>

    <main class="bg-[#002039]">
      <section id="news" class="flex flex-col items-center justify-center border-t-4 border-[#4B8BF4]">
        <!-- News Header -->
        <div class="flex bg-[#175585] py-8 md:px-12 px-6 items-center justify-between w-full">
          <div class="flex flex-col">
            <h2 class="text-white text-xl md:text-2xl font-bold tracking-tight uppercase flex items-center gap-3">
              Latest News
              <div class="w-2 h-2 bg-[#4B8BF4] rounded-full animate-pulse"></div>
            </h2>
            <p class="text-white/60 text-sm mt-1">Stay updated with our latest announcements</p>
          </div>
          
          <button 
            class="text-white hover:text-[#4B8BF4] transition-colors duration-200 text-sm md:text-base flex items-center gap-2 group" 
            id="viewallbutton" 
            @click="showallnews"
          >
            <span class="font-medium">View all</span>
            <svg xmlns="http://www.w3.org/2000/svg" 
              class="h-4 w-4 group-hover:translate-x-1 transition-transform" 
              fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>

        <!-- News Grid -->
        <section class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 max-w-7xl mx-auto p-8 w-full newsboxcontainer">
          <!-- News boxes will be dynamically inserted here -->
        </section>
      </section>

      <section class="bg-[#175585]/10 backdrop-blur-sm">
        <!-- Header -->
        <div class="flex bg-[#175585] py-8 md:px-12 px-6 items-center justify-between w-full border-t-4 border-[#4B8BF4]">
          <div class="flex flex-col">
            <h2 class="text-white text-xl md:text-2xl  font-bold tracking-tight uppercase flex items-center gap-3">
              Next Match
              <div class="w-2 h-2 bg-[#4B8BF4] rounded-full animate-pulse"></div>
            </h2>
            <p class="text-white/60 text-sm mt-1">Upcoming game details</p>
          </div>  
        </div>

        <!-- Next Match Content -->
        <div class="flex flex-col text-white items-center justify-center py-12 md:py-16 px-6">
          <!-- Teams -->
          <div class="flex items-center gap-8 md:gap-20 max-w-2xl w-full justify-center">
            <!-- Home Team -->
            <div class="text-center flex-1 max-w-[200px]">
              <div class="w-16 h-16 md:w-24 md:h-24 bg-white/10 rounded-full flex items-center justify-center mb-3 mx-auto">
                <span class="text-xl md:text-3xl font-bold">{{ match1value.charAt(0) }}</span>
              </div>
              <h3 class="font-semibold text-sm md:text-xl">{{ match1value }}</h3>
            </div>

            <!-- VS -->
            <div class="flex flex-col items-center gap-3 flex-shrink-0">
              <span class="text-lg md:text-2xl font-bold text-[#4B8BF4]">VS</span>
              <div class="px-4 py-1.5 bg-[#4B8BF4]/20 rounded-full">
                <span class="text-xs md:text-sm text-[#4B8BF4] font-medium">{{ datevalue }}</span>
              </div>
            </div>

            <!-- Away Team -->
            <div class="text-center flex-1 max-w-[200px]">
              <div class="w-16 h-16 md:w-24 md:h-24 bg-white/10 rounded-full flex items-center justify-center mb-3 mx-auto">
                <span class="text-xl md:text-3xl font-bold">{{ match2value.charAt(0) }}</span>
              </div>
              <h3 class="font-semibold text-sm md:text-xl">{{ match2value }}</h3>
            </div>
          </div>

          <!-- Location -->
          <div class="mt-8 flex items-center gap-2 text-white/70">
            <svg xmlns="http://www.w3.org/2000/svg" 
              class="h-4 w-4" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor">
              <path stroke-linecap="round" 
                stroke-linejoin="round" 
                stroke-width="2" 
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" 
                stroke-linejoin="round" 
                stroke-width="2" 
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span class="text-sm md:text-base">{{ locationvalue }}</span>
          </div>
        </div>
      </section>

      <section class="cards_sponsors bg-[#002039]">
        <section id="teams" class="md:pb-20 pb-5 currentteams">
          <div class="flex bg-[#175585] py-8 md:px-12 px-6 items-center justify-between w-full border-t-4 border-[#4B8BF4]">
            <div class="flex flex-col">
              <h2 class="text-white text-xl md:text-2xl font-bold tracking-tight uppercase flex items-center gap-3">
                Current Teams
                <div class="w-2 h-2 bg-[#4B8BF4] rounded-full animate-pulse"></div>
              </h2>
              <p class="text-white/60 text-sm mt-1">Meet our talented football teams</p>


              <div class="flex gap-2 mt-4 md:hidden absolute right-10">
                <button @click="pastteams = false" class="text-white/60 hover:text-[#4B8BF4] transition-colors duration-200">Current</button>
                <button @click="pastteams = true" class="text-white/60 hover:text-[#4B8BF4] transition-colors duration-200">Past</button>
              </div>
            </div>

   
          </div>

          <div class="hidden md:flex w-full items-center py-4 md:my-10 justify-center">
            <div class="flex gap-1 bg-white/20 text-white px-1 w-56 py-1 rounded">
              <button @click="pastteams = false" :class="{ 'bg-black/40': !pastteams }"
                class="w-1/2 rounded py-1 px-4 duration-200">Current</button>

              <button @click="pastteams = true" :class="{ 'bg-black/40': pastteams }"
                class="w-1/2 rounded py-1 px-4 duration-200">Past</button>
            </div>
          </div>

          <div class="bg-[#175585]/10 rounded-3xl pt-10 md:pt-0 md:w-4/5 max-w-[900px] mx-auto mt-1.5 md:mt-0">
            <Swiper 
              :grabCursor="true" 
              :centeredSlides="true" 
              :initialSlide="0" 
              :slidesPerView="1"
              :direction="'vertical'" 
              :pagination="{ el: '.swiper-pagination', clickable: true }" 
              :effect="'fade'"
              :fadeEffect="{ crossFade: true }" 
              :modules="modules" 
              pagination="true" 
              :class="{ 'h-[250px]': pastteams }"
              class="teams-swiper h-[430px] md:h-[600px] lg:h-[500px] font-[Fira Sans] overflow-visible relative" 
              @slideChange="onSlideChange"
              @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
              :navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }">

              <swiper-slide v-for="(team, index) in teams" :key="index"
                class="teamsswiper flex flex-col md:flex-row md:pb-4 overflow-visible items-center justify-start md:justify-center md:p-4 px-8 md:px-16 md:gap-12">
                <!-- Current Teams View -->
                <template v-if="!pastteams">
                  <img loading="lazy" alt="TAS Football teams" title="TAS Football teams" class="rounded-xl md:w-1/2 max-w-[450px]" :src="team.image" />
                  <div class="team-content bg-[#175585] md:bg-[#175585]/80 backdrop-blur-sm rounded-xl p-6 md:p-8 w-full md:w-1/2 mt-4 md:mt-0">
                    <h4 class="text-xl md:text-2xl font-bold text-white mb-2 md:mb-3">{{ team.teamName }}</h4>
                    <p class="text-[#4B8BF4] font-medium mb-3 md:mb-4 text-sm md:text-base">Manager: {{ team.manager }}</p>
                    <p class="text-white/80 text-sm md:text-base leading-relaxed">{{ team.description }}</p>
                  </div>
                </template>
                
                <!-- Past Teams View - Image Only -->
                <template v-else>
                  <img loading="lazy" alt="TAS Football teams" title="TAS Football teams" class="!w-auto !mx-auto rounded-xl !sm:h-full mt-3 md:h-auto md:mt-0" :src="team.image2" />
                </template>
              </swiper-slide>

              <div class="swiper-pagination hidden sm:block absolute right-8 top-1/2 -translate-y-1/2 w-4 p-1 rounded-full"></div>
              <div class="swiper-button-prev w-5 h-5 md:ml-6 mt-44"></div>
              <div class="swiper-button-next w-5 h-5 md:mr-6 mt-44"></div>
            </Swiper>
          </div>
        </section>
        <SponsorsComp />
      </section>

      <section id="policies" class="mx-auto flex flex-col text-white justify-center">
        <!-- Header -->
        <div class="flex bg-[#175585] py-8 md:px-12 px-6 items-center justify-between w-full border-t-4 border-[#4B8BF4]">
          <div class="flex flex-col">
            <h2 class="text-white text-xl md:text-2xl  font-bold tracking-tight uppercase flex items-center gap-3">
              Policies
              <div class="w-2 h-2 bg-[#4B8BF4] rounded-full animate-pulse"></div>
            </h2>
            <p class="text-white/60 text-sm mt-1">Club rules and guidelines</p>
          </div>
          <p class="text-[#4B8BF4] text-sm font-medium">Applicable on: <span id="date" class="text-white/70"></span></p>
        </div>

        <!-- Policy Content -->
        <div class="px-6 md:px-12 py-8 space-y-6 md:w-4/5 mx-auto">
          <!-- Spectators Policy -->
          <div class="w-full rounded-xl bg-white/5 hover:bg-white/10 transition-colors duration-300">
            <button @click="ExpandPolicy($event.currentTarget)"
              class="w-full flex items-center justify-between p-6 cursor-pointer group">
              <div class="flex items-center gap-3">
                <div class="bg-[#4B8BF4]/20 p-3 rounded-lg group-hover:bg-[#4B8BF4]/30 transition-colors duration-300">
                  <svg xmlns="http://www.w3.org/2000/svg" 
                    class="h-6 w-6 text-[#4B8BF4]" 
                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </div>
                <h3 class="text-lg md:text-xl font-semibold tracking-tight">Spectators and Parents/Carers</h3>
              </div>
              <div class="plusminus w-8 h-8 bg-white/10 rounded-lg flex items-center justify-center transition-transform duration-300"></div>
            </button>

            <div class="text-sm h-0 opacity-0 overflow-hidden transition-all duration-300 policytext">
              <div class="p-6 pt-0 space-y-6 text-white/80">
                <ul class="list-disc pl-5 space-y-2 text-white/80">
                  <li>Have fun; it's what we're all here for!</li>
                  <li>Celebrate effort and good play from both sides</li>
                  <li>Always respect the Referee and coaches and encourage players to do the same</li>
                  <li>Stay behind the touchline and within the Designated Spectators' Area (where provided)</li>
                  <li>When players make mistakes, offer them encouragement to try again next time</li>
                  <li>Never engage in, or tolerate offensive, insulting or abusive language or behaviour</li>
                </ul>

                <div class="space-y-2">
                  <p class="font-semibold">I understand that if I do not follow the Code, I may be:</p>
                  <ul class="list-disc pl-5 text-white/80">
                    <li>Issued with a verbal warning or asked to leave</li>
                    <li>Required to meet with the club committee, league or CFA Welfare Officer</li>
                    <li>Requested not to attend future games, be suspended or have my membership removed</li>
                    <li>Required to leave the club along with any dependants and/or issued a fine</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Young Players Policy -->
          <div class="w-full rounded-xl bg-white/5 hover:bg-white/10 transition-colors duration-300">
            <button @click="ExpandPolicy($event.currentTarget)"
              class="w-full flex items-center justify-between p-6 cursor-pointer group">
              <div class="flex items-center gap-3">
                <div class="bg-[#4B8BF4]/20 p-3 rounded-lg group-hover:bg-[#4B8BF4]/30 transition-colors duration-300">
                  <svg xmlns="http://www.w3.org/2000/svg" 
                    class="h-6 w-6 text-[#4B8BF4]" 
                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                      d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 class="text-lg md:text-xl font-semibold tracking-tight">Young Players</h3>
              </div>
              <div class="plusminus w-8 h-8 bg-white/10 rounded-lg flex items-center justify-center transition-transform duration-300"></div>
            </button>

            <div class="text-sm h-0 opacity-0 overflow-hidden transition-all duration-300 policytext">
              <div class="p-6 pt-0 space-y-6 text-white/80">
                <ul class="list-disc pl-5 space-y-2 text-white/80">
                  <li>Always play my best for the benefit of the team</li>
                  <li>Play fairly and be friendly</li>
                  <li>Play by the rules and respect the Referee</li>
                  <li>Shake hands with the other team - win or lose</li>
                  <li>Listen carefully to what my coach tells me</li>
                  <li>Understand that a coach has to do what's best for the team</li>
                  <li>Talk to someone I trust or the club welfare officer if I'm unhappy about anything at my club</li>
                  <li>Encourage my team mates</li>
                  <li>Respect the facilities home & away</li>
                </ul>

                <div class="space-y-2">
                  <p class="font-semibold">I understand that if I do not follow the Code, I may be:</p>
                  <ul class="list-disc pl-5 text-white/80">
                    <li>Asked to apologise to whoever I've upset</li>
                    <li>Receive a formal warning</li>
                    <li>Be dropped, substituted or suspended from training</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-10" id="contact">
        <div class="flex bg-[#175585] py-8 md:px-12 px-6 mb-10 items-center justify-between w-full border-t-4 border-[#4B8BF4]">
          <div class="flex flex-col">
            <h2 class="text-white text-xl md:text-2xl font-bold tracking-tight uppercase flex items-center gap-3">
              Contact Us
              <div class="w-2 h-2 bg-[#4B8BF4] rounded-full animate-pulse"></div>
            </h2>
            <p class="text-white/60 text-sm mt-1">Get in touch with our team</p>
          </div>
        </div>
        <ContactForm />
      </section>
    </main>

    <footer class="bg-[#175585] text-white py-12">
      <div class="max-w-6xl mx-auto px-4">
        <!-- Top Section -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12">
          <!-- Contact Info -->
          <div class="text-center">
            <h3 class="text-lg font-bold mb-4 flex items-center gap-2 justify-center">
              <img loading="lazy" alt="Picture of a football" title="Picture of a football" class="w-5" src="../assets/football.png">
              Contact Us
            </h3>
            <ul class="space-y-3">
              <li v-for="contact in [
                { href: 'tel:+44 7709 640872', icon: 'phone', text: '+44 7709 640872' },
                { href: 'mailto:tasfc50@gmail.com', icon: 'email', text: 'tasfc50@gmail.com' }
              ]">
                <a :href="contact.href" 
                  class="flex items-center gap-2 justify-center hover:text-[#4B8BF4] transition-colors group">
                  <svg xmlns="http://www.w3.org/2000/svg" 
                    class="h-4 w-4 group-hover:scale-110 transition-transform" 
                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <!-- Add appropriate path for each icon -->
                  </svg>
                  {{ contact.text }}
                </a>
              </li>
            </ul>
          </div>

          <!-- Quick Links -->
          <div class="text-center">
            <h3 class="text-lg font-bold mb-4">Quick Links</h3>
            <div class="grid grid-cols-2 gap-3 max-w-[300px] mx-auto">
              <template v-for="item in ['home', 'news', 'teams', 'committee', 'policies', 'contact']">
                <button @click="ScrollTo(item)" 
                  class="hover:text-[#4B8BF4] transition-colors hover:scale-105 duration-200">
                  {{ item.charAt(0).toUpperCase() + item.slice(1) }}
                </button>
              </template>
            </div>
          </div>
        </div>

        <!-- Bottom Section -->
        <div class="border-t border-white/20 pt-8 text-center">
          <div class="flex flex-wrap justify-center gap-4 mb-4 text-white/70 text-sm">
            <p>Copyright © {{ copyrightyear }} TasFC</p>
            <p>•</p>
            <p>Icons by <a href="https://icons8.com" target="_blank" 
              class="hover:text-[#4B8BF4] transition-colors">Icons8</a></p>
          </div>
          <p class="text-white/50 text-xs">
            Created with ♱ by <a href="https://jacksweb.co.uk" target="_blank" 
              class="text-[#4B8BF4] hover:text-[#4B8BF4]">JacksWeb</a>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted, defineAsyncComponent, createApp } from 'vue';

// Supabase
import { supabase } from '../clients/supabase';

// Swiper js
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectFade, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Components
const SponsorsComp = defineAsyncComponent(() => 
  import('@/components/Sponsors.vue')
);
const ContactForm = defineAsyncComponent(() => 
  import('@/components/ContactForm.vue')
);

const modules = [EffectFade, Navigation];
const match1value = ref("");
const match2value = ref("");
const datevalue = ref("");
const locationvalue = ref("");
const news_headline = ref("");
const copyrightyear = getYear();
const pastteams = ref(false);
const currentteams = ref(true);

const navlinks = [
  { name: "Home", url: "#" },
  { name: "News", url: "#news" },
  { name: "Committee", url: "#committee" },
  { name: "Teams", url: "#teams" },
  { name: "Policies", url: "#policies" },
]

const teams = [
  {
    manager: "Craig Duff",
    teamName: "U8's",
    description: "This is our U8's team. Managed by Craig Duff with assisted coaching from Tom Bearne.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/1.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team1.jpeg?raw=true'
  },
  {
    manager: "Jamie Munro",
    teamName: "U8 Trojans",
    description: "This is our U8 Trojans team. Managed by Jamie Munro with assisted coaching from Paul Shepard and Mike Carrasco.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/2.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team2.jpeg?raw=true'
  },
  {
    manager: "Barry Munro",
    teamName: "U9 Predators",
    description: "This is our U9 Predators team. Managed by Barry Munro with assisted coaching from David Tucker.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/3.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team3.jpeg?raw=true'
  },
  {
    manager: "Alan Gowland",
    teamName: "U10 Colts",
    description: "This is our U10 Colts team. Managed by Alan Gowland with assisted coaching from Phil Lerendu.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/4.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team4.jpeg?raw=true'
  },
  {
    manager: "Karl Williams",
    teamName: "U10 Trojans",
    description: "This is our U10 Trojans team. Managed by Karl Williams with assisted coaching from David Grove and Pete Coleman.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/5.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team5.jpeg?raw=true'
  },
  {
    manager: "James Wenman",
    teamName: "U11 Blues",
    description: "This is our U11 Blues team. Managed by James Wenman with assisted coaching from Sam Stephans.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/6.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team6.jpeg?raw=true'
  },
  {
    manager: "Lee Davis",
    teamName: "U12's",
    description: "This is our U12's team. Managed by Lee Davis with assisted coaching from Adam Newman.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/7.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team7.jpeg?raw=true'
  },
  {
    manager: "Dan Butterworth",
    teamName: "U12 Colts",
    description: "This is our U12 Colts team. Managed by Dan Butterworth with assisted coaching from Steven Courtier, Adam Robertson, and Paul Wood.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/8.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team8.jpeg?raw=true'
  },
  {
    manager: "Luke Asplen",
    teamName: "U14's",
    description: "This is our U14's team. Managed by Luke Asplen with assisted coaching from Derek Fields and Neville Wrathall.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/9.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team9.jpeg?raw=true'
  },
  {
    manager: "Des Pitman",
    teamName: "U14 Predators",
    description: "This is our U14 Predators team. Managed by Des Pitman with assisted coaching from Scott Somerset and Chris Watts.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/10.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team10.jpeg?raw=true'
  },
  {
    manager: "John English",
    teamName: "U15 Tornados",
    description: "This is our U15 Tornadoes team. Managed by John English with assisted coaching from Phil Lerendu and Cavan Kirby.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/11.jpg?raw=true',
  },
];

function ScrollTo(id) {
  const newsboxcontainer = document.querySelector('#' + id);
  newsboxcontainer.scrollIntoView({ behavior: 'smooth' });
}

function nextslide() {
  const swiper = document.querySelector('.teams-swiper').swiper;
  swiper.slideNext();
}
function prevslide() {
  const swiper = document.querySelector('.teams-swiper').swiper;
  swiper.slidePrev();
}

function getYear() {
  return new Date().getFullYear();
}

function getCurrentDate() {
  const currentDateElement = document.getElementById('date');

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  currentDateElement.textContent = `${day}/${month}/${year}`;
}

function HideExpandedNews() {
  const expandednews = document.querySelector('.expandednews');

  expandednews.style.opacity = "0";
}

async function get_news_db() {
  const newsboxcontainer = document.querySelector('.newsboxcontainer');
  
  let { data: news_table, error } = await supabase
    .from('news_table')
    .select('*')
    .order('news_date', { ascending: false });

  if (error) {
    console.error('Error fetching data:', error.message);
    return;
  }

  newsboxcontainer.innerHTML = ''; // Clear existing content
  
  news_table.forEach((news, index) => {
    const newsBox = createNewsBox(news, index);
    if (newsBox) {
      newsboxcontainer.appendChild(newsBox);
    }
  });

  const seemorebutton = document.querySelector('#viewallbutton');
  seemorebutton.style.display = newsboxcontainer.children.length <= 3 ? "none" : "flex";
}

function createNewsBox(news, index) {
  if (!news.news_heading || news.news_heading.trim() === "null") return;
  
  const newsComponent = defineAsyncComponent(() => import('@/components/NewsCard.vue'));
  const newsWrapper = document.createElement('div');
  newsWrapper.classList.add('news_boxes');
  
  createApp(newsComponent, { news })
    .mount(newsWrapper);
    
  return newsWrapper;
}

async function get_DB_Values() {
  get_news_db()

  const { data, error } = await supabase
    .from('tas_database')
    .select('*')
    .eq('id', 22);

  if (error) {
    console.error('Error fetching data:', error);
    return;
  }

  const rowData = data[0];

  if (!rowData) {
    console.error('No data found for id 22');
    return;
  }

  match1value.value = rowData.match_1 || '';
  match2value.value = rowData.match_2 || '';
  news_headline.value = rowData.news || '';
  locationvalue.value = rowData.location || '';
  datevalue.value = rowData.date || '';
}
function expandnav() {
  const nav = document.querySelector(".hiddennav");
  const isExpanded = nav.style.display === "block";

  if (isExpanded) {
    nav.style.transform = "translateY(-10px)";
    nav.style.opacity = "0";
    setTimeout(() => {
      nav.style.display = "none";
    }, 300);
  } else {
    nav.style.display = "block";
    setTimeout(() => {
      nav.style.transform = "translateY(0)";
      nav.style.opacity = "1";
    }, 10);
  }
}

function showallnews() {
  const newsbox4 = document.querySelector('.news_boxes:nth-child(4)');
  const newsbox5 = document.querySelector('.news_boxes:nth-child(5)');
  const viewallbutton = document.querySelector('#viewallbutton');

  // Toggle the display of the next two news boxes
  if (viewallbutton.textContent === 'View all') {
    if (newsbox4) {
      newsbox4.style.display = "block";
    }
    if (newsbox5) {
      newsbox5.style.display = "block";
    }


    viewallbutton.textContent = "View less";
  } else {
    if (newsbox4) {
      newsbox4.style.display = "none";
    }
    if (newsbox5) {
      newsbox5.style.display = "none";
    }

    viewallbutton.textContent = "View all";
  }
}


function ExpandPolicy(button) {
  const policybox = button.closest('.bg-white\\/5');
  const plusminus = button.querySelector('.plusminus');
  const policytext = policybox.querySelector('.policytext');
  const isActive = plusminus.classList.contains('active');

  plusminus.classList.toggle('active');

  if (!isActive) {
    policytext.style.height = policytext.scrollHeight + 'px';
    policytext.style.opacity = '1';
  } else {
    policytext.style.height = '0';
    policytext.style.opacity = '0';
  }
}

onMounted(() => {
  get_DB_Values();

  const header = document.querySelector('.tasheader');
  const nav = document.querySelector('.normalnav');
  const hamburger = document.querySelector('.hamburger');

  window.onscroll = () => {
    const isScrolled = window.scrollY > 2;

    // nav.style.height = isScrolled ? '38px' : '48px';
    // header.style.fontSize = isScrolled ? '13px' : '18px';
    // hamburger.classList.toggle('hamburgerscrolled', isScrolled);
  };

  getCurrentDate();
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

html{scroll-behavior:smooth}
.v-enter-active,.v-leave-active{transition:opacity .5s ease}
.v-enter-from,.v-leave-to{opacity:0}

.swiper-pagination{
  .swiper-pagination-bullet{transition:all .3s;
    &-active{height:20px;border-radius:100px}
  }
}

.readmore-button{background:linear-gradient(to right,#4ab1f1,#074081)}
.footballimage{animation:ease-in-out 5s infinite footballAnimation}

#teamsrightsection{
  background-image:url('https://images.unsplash.com/photo-1624880357913-a8539238245b?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size:cover;
  background-position:center
}

.committemembers:hover .scroll-container{animation-play-state:paused}
.commmembers:hover{scale:1.05}

.plusminus{
  position:relative;
  cursor:pointer;
  &::before,&::after{
    content:"";
    position:absolute;
    background-color:#4B8BF4;
    border-radius:2px;
    transition:all .3s ease
  }
  &::before{width:2px;height:16px;transform:translateX(-50%)}
  &::after{width:16px;height:2px;transform:translateY(-50%)}
  &.active{
    &::before{transform:rotate(90deg) scale(0)}
    &::after{transform:rotate(180deg)}
  }
}

.policyboxes.active .policytext{opacity:1}
.scroll-container{
  animation:marquee 15s linear infinite;
  div{@apply bg-cover bg-center bg-no-repeat}
}

.pastteams-container .swiperslide{
  @apply size-40 scale-90 sm:size-52 rounded duration-300 opacity-50;
  background-position:center;
  background-size:cover;
  background-repeat:no-repeat;
  &.swiper-slide-active{@apply scale-100 opacity-100}
}

:global(.swiper-pagination-bullet){@apply bg-white}
.swiper-button-prev,.swiper-button-next{@apply text-white}
.swiper-button-prev::after,.swiper-button-next::after{@apply text-3xl}

.topheader{
  background-image:url('https://images.unsplash.com/photo-1517927033932-b3d18e61fb3a?q=80&w=1338&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  @apply bg-cover bg-center bg-no-repeat
}

// Navigation styles
.hamburger{
  @apply mt-[5px];
  div{@apply relative w-6 h-[2px] rounded-[3px] bg-white mt-[5px] duration-300}
}
.hamburgerscrolled{@apply scale-[0.8];top:2px!important;right:5px!important}

// Toggle states
#toggle:checked+.hamburger{
  .top-bun{@apply mt-[14px] -rotate-45}
  .bottom-bun{@apply rotate-45 opacity-0}
  .meat{@apply -mt-[2px] rotate-45}
  &+.nav{@apply scale-100 top-0}
}

.team-match{@apply -skew-x-[10deg]}
.news_boxes{
  @apply w-full;
  &:nth-child(n+4){display:none}
}

// Scrollbar styles
.policyboxes{
  &::-webkit-scrollbar{width:10px}
  &::-webkit-scrollbar-track{background:black}
  &::-webkit-scrollbar-thumb{
    background:rgba(255,255,255,.455);
    border-radius:50px
  }
}

// Teams swiper styles
.teamsswiper{
  &.swiper-slide-active{
    div,img{opacity:1;transform:none;transition-delay:.3s}
  }
  img{@apply w-full h-48 md:h-[300px] object-cover rounded-xl shadow-lg}
  .team-content{
    @apply mt-4 md:mt-0 transform transition-all duration-300 max-w-[500px];
    &:hover{@apply bg-[#175585]/90}
    p{@apply max-w-none}
  }
  div,img{
    opacity:0;
    transform:translateY(20px);
    transition:all .3s ease
  }
}

// Animations
@keyframes footballAnimation{
  0%{transform:translateY(0)}
  50%{transform:translateY(-15px)}
  100%{transform:translateY(0)}
}

@keyframes marquee{
  0%{transform:translateX(0)}
  100%{transform:translateX(calc(-100% + 100vw))}
}

@keyframes spin-slow{
  from{transform:rotate(0deg)}
  to{transform:rotate(360deg)}
}

.animate-spin-slow{animation:spin-slow 8s linear infinite}
.line-clamp-3{
  display:-webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical;
  overflow:hidden
}

// Policy and swiper navigation styles
.policytext{height:0;opacity:0;transition:all .3s ease}
.policyboxes.active .policytext{opacity:1}

.swiper-button-prev,.swiper-button-next{
  @apply text-[#4B8BF4] bg-white/10 rounded-full p-6 backdrop-blur-sm;
  &:hover{@apply bg-white/20}
  &::after{@apply text-lg font-bold}
}

.swiper-pagination-bullet{
  @apply bg-[#4B8BF4] opacity-50;
  &-active{@apply opacity-100}
}
</style>